<template>
  <div class="mx-4 my-4 md:mx-8">
    <Breadcrumb :links="breadcrumb" />
    <br />
    <h1 class="text-3xl mb-5">Nova Validação</h1>

    <div class="mt-5 md:mt-0 md:col-span-2">
      <form action="#" method="POST">
        <div class="shadow overflow-hidden sm:rounded-md">
          <div class="px-4 py-5 bg-white sm:p-6">
            <div class="grid grid-cols-12 gap-6">
              <div class="col-span-6">
                <div class="grid grid-cols-12 gap-3">
                  <div class="col-span-12"> 
                    <a
                      class="mt-1 mr-2 underline text-right"
                      :href="`/arquivo-modelo-lembrazap-validacao.xlsx`"
                    >
                      Arquivo modelo
                    </a>
                  </div>
                  <div class="col-span-12"> 
                    <label
                      disabled="disabled"
                      for="name"
                      class="mt-2 block text-sm font-medium text-gray-700"
                      >Identificação (nome do arquivo automático)</label
                    >
                    <input
                      disabled="disabled"
                      required
                      v-model="form.nome"
                      type="text"
                      name="name"
                      id="name"
                      class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <div class="col-span-12 md:col-span-5" v-if="$store.state.user && $store.state.user.admin"> 
                    <label for="centroscusto" class="block text-sm font-medium text-gray-700">Centro de custo</label>
                    <v-select v-model="form.centrocusto" :options="centroscusto" label="nome" :reduce="(c) => c._id" name="centrocusto" id="centrocusto" class="mt-1"></v-select>
                  </div>
                  <div class="col-span-12"> 
                    <label
                      for="attachmenFile"
                      class="mb-2 block text-sm font-medium text-gray-700"
                      >Arquivo para importar</label
                    >
                    <vue-dropzone
                      required
                      ref="doc"
                      id="doc"
                      class="w-full"
                      :options="dropzoneCampaignOptions"
                      v-on:vdropzone-success="updateCampaign"
                      v-on:vdropzone-removed-file="removeFileCampaign"
                    >
                    </vue-dropzone>
                  </div>
                </div>
              </div>
              <div class="col-span-6">
                <div class="grid grid-cols-2">
                  <h1 class="text-2xl mb-8">Amostra</h1>
                  <small class="mt-3 text-right">{{ previews.length }} contatos</small>
                </div>
                <div
                  class="shadow overflow-auto max-h-96 border-b border-gray-200 sm:rounded-lg"
                >
                  <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Números
                        </th>
                      </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                      <tr v-for="preview in previews" :key="preview.contato.telefone">
                        <td class="px-6 py-4 whitespace-nowrap">
                          {{ preview.contato.telefone }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="grid grid-cols-2">
            <div class="px-4 py-3 bg-gray-50 text-left sm:px-6">
              <router-link
                to="/temzap"
                class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
              >
                {{ $t("txt-back") }}
              </router-link>
            </div>
            <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
              <button
                @click="save"
                type="button"
                class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
              >
                {{ $t("txt-save") }}
              </button>
            </div>
          </div>
        </div>
      </form>
      <div v-if="isLoading" class="fixed z-50 top-80 left-0 w-full text-center text-lg text-zinc-600 font-bold">
        <span class="text-green-600">{{ progresso }}</span> / 100%
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import vueDropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import Breadcrumb from "../../components/Breadcrumbs.vue";

export default {
  components: {
    vueDropzone,
    Breadcrumb,
  },
  data() {
    return {
      isLoading: false,
      progresso: 0,
      breadcrumb: [
        { url: "/temzap", value: "Validação Whatsapp" },
        { url: "/temzap/import", value: "Nova validação" },
      ],
      route: "campaign",
      partitionCampaign: false,
      moment,
      centroscusto: [],
      previews: [],
      zaps: [],
      shootingDate: moment().toISOString(),
      shootingTime: moment().startOf("day").format("HH:mm"),
      form: {
        nome: "",
        dataDisparo: "",
        horaDisparo: "",
        file: "",
        dataHoraDisparo: "",
        arquivo: "",
        metodo: "",
        centrocusto: null,
        validacao: true,
      },
      dropzoneAttachmenOptions: this.$http.getDropzoneConfig(`upload`, "post", {
        maxFiles: 1,
        addRemoveLinks: true,
        capture: true,
        dictDefaultMessage: `Clique ou arraste o arquivo aqui`,
        dictRemoveFile: "Remover",
      }),
      dropzoneCampaignOptions: this.$http.getDropzoneConfig(
        `campanhasLZV2/preview-campanha-import`,
        "post",
        {
          maxFiles: 1,
          addRemoveLinks: true,
          capture: true,
          dictDefaultMessage: `Clique ou arraste o arquivo da campanha aqui`,
          dictRemoveFile: "Remover",
        }
      ),
    };
  },
  mounted() {
    this.sockets.subscribe(`progressao_${this.$store.state.user._id}`,
      (progresso) => {
        this.progresso = progresso;
    });
  },
  beforeDestroy() {
    this.sockets.unsubscribe(`progressao_${this.$store.state.user._id}`);
  },
  methods: {
    update(file, response) {
      this.form.arquivo = response.file;
    },
    updateCampaign(file, response) {
      this.form.file = file;
      this.form.nome = file.name;
      this.previews = response.data;
    },
    removeFileCampaign() {
      this.form.file = null;
      this.form.nome = `${moment().format("x")}`;
      this.previews = [];
    },
    async save() {
      const dataEnvio = new FormData();
      const dataHoraDisparo = moment(
        `${this.form.dataDisparo} ${this.form.horaDisparo}`,
        "YYYY-MM-DD HH:mm"
      ).toDate();

      dataEnvio.append("file", this.form.file);
      dataEnvio.append("arquivo", JSON.stringify(this.form.arquivo));
      dataEnvio.append("dataHoraDisparo", dataHoraDisparo);
      dataEnvio.append("nome", this.form.nome);
      dataEnvio.append("validacao", this.form.validacao);

      this.isLoading = true;
      let loader = this.$loading.show({
        container: null,
        canCancel: true
      });

      if (this.$store.state.user.lzv2.centrocusto && !this.form.centrocusto) {
        loader.hide();
        this.isLoading = false;
        return this.$vToastify.error("Selecione o centro de custo");
      }

      if (this.form.centrocusto) {
        dataEnvio.append("centrocusto", this.form.centrocusto);
      }

      const result = await this.$http.post(
        "/v1/campanhasLZV2/gerar-campanha-import",
        dataEnvio
      );

      this.isLoading = false;
      loader.hide();
      if (result.data.success) {
        this.$router.push({ path: "/temzap" });
      } else {
        this.$vToastify.error(result.data.err);
      }
    },
  },
  async beforeMount() {
    this.form.nome = `${moment().format("x")}`;
    this.form.dataDisparo = moment().format("YYYY-MM-DD");
    this.form.horaDisparo = moment().add(15,'minutes').format("HH:mm");
    this.form.ignoraTrava = true;
    this.form.validacao = true;

    const centroscustoReq = await this.$http.post(`/v1/centrocusto/list`, { all: true });
    if(centroscustoReq.data && centroscustoReq.data.data) this.centroscusto = centroscustoReq.data.data;

    if (this.$store.state.user && this.$store.state.user.centrocusto && !this.$store.state.user.admin) {
      this.form.centrocusto = this.$store.state.user.centrocusto;
    }
  },
};
</script>
